<template>
  <div id="app">
    <Preloader/>
    <CustomCursor />
    <Hero/>
    <Contact/>
    <Footer/>
  </div>
</template>

<script>
import Preloader from '@/components/decors/Preloader.vue'
import CustomCursor from '@/components/decors/CustomCursor.vue'
import Hero from '@/components/Hero.vue'
import Contact from '@/components/Contact.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Preloader,
    CustomCursor,
    Hero,
    Contact,
    Footer
  },
  data () {
    return {
      vh: window.innerHeight * 0.01
    }
  },
  methods: {
    calcHero() {
      this.vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${this.vh}px`)
    }
  },
  created() {
    window.addEventListener('resize', this.calcHero)
    this.calcHero()
  },
  destroyed() {
        window.removeEventListener("resize", this.calcHero)
  }
}
</script>