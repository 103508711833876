<template>
    <div class="footer">
        <div class="footer__container">
            <div class="footer__left">
                <p>
                    <span>© Modan </span>
                    <span>{{currentYear}}</span>
                </p>
            </div>
            <div class="footer__right">                
                <img class="footer__right--phone" src="img/phone.jpg" style="height:24px;" />
                <p class="footer__right--line">|</p>
                <a 
                    class="footer__right--email crpytedEmail"
                    data-name="hello"
                    data-domain="modan"
                    data-tld="hu"
                    onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
                </a>
                <p class="footer__right--line">|</p>
                <a 
                    class="footer__right--phone"
                    href="pdf/altalanos-adatvedelmi-tajekoztato.pdf" target="_blank">
                    Privacy Policy
                </a>   
                <p class="footer__right--line">|</p>
                <div class="footer__right--social">
                    <a href="https://www.behance.net/modandesign" target="_blank">
                        <img class="svg-icon" src="/img/behance.svg" alt="Béhance">
                    </a>
                    <!-- <a href="https://www.behance.net/modandesign" target="_blank"><img src="/img/linkedin.svg" alt="LinkedIn"></a> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentYear: function () {
            return new Date().getFullYear()
        }
    }
}
</script>