<template>
    <button 
        v-scroll-to="link"
        class="button">
        <div class="button__text uppercase">{{button}}</div>
    </button>
</template>

<script>
export default {
    props: ['button', 'link']
}
</script>