<template>
    <transition
        name="slide-up"
        mode="out-in">
        <div v-if="preloaderIs" class="preloader"></div>
    </transition>
</template>

<script>
export default {
    data () {
        return {
            preloaderIs: true
        }
    },
    methods: {
        hidePreloader(){
            setTimeout(() => {
                this.preloaderIs = false
            }, 1500)
        }
    },
    mounted () {
        this.hidePreloader()
    }
}
</script>

<style lang="sass">

.pre-enter
    opacity: 0

.pre-enter-active,
.pre-leave-active
    transition: all 0.75s ease

.pre-leave-to
    opacity: 0

.pre-move
  transition: transform 0.75s ease

</style>