<template>
    <div class="contact" id=contact>
        <div class="contact__container">
            
            <div class="contact__form">
                <div class="contact__form--title">
                    <h3>We'd love to</h3>
                    <h2 class="semi-bold">hear from you!</h2>
                </div>
                <form @submit.prevent="submitForm">
                    <div class="form-twin">
                        <div class="form-item">
                            <label
                                :class="{ 'accept-error' : v$.name.$error }"
                                class="label" 
                                for="name">
                            Name*
                            </label><br>
                            <input 
                                :class="{ 'form-error' : v$.name.$error }"
                                class="input" 
                                v-model="name" 
                                type="text" 
                                id="name" 
                                name="name" 
                                placeholder="Your name">
                        </div>
                        <div class="form-item">
                            <label class="label" for="company">Company name</label><br>
                            <input 
                                class="input" 
                                v-model="company"
                                type="text" 
                                id="company" 
                                name="company" 
                                placeholder="Your company">
                        </div>
                    </div>
                    <div class="form-twin">
                        <div class="form-item">
                            <label 
                                :class="{ 'accept-error' : v$.email.$error }" 
                                class="label" 
                                for="email">Email*</label><br>
                            <input 
                                :class="{ 'form-error' : v$.email.$error }"
                                class="input" 
                                v-model="email" 
                                type="text" 
                                id="email" 
                                name="email" 
                                placeholder="Your email">
                        </div>
                        <div class="form-item">
                            <label class="label" for="phone">Phone Number</label><br>
                            <input 
                                class="input" 
                                v-model="phone" 
                                type="text" 
                                id="phone" 
                                name="phone" 
                                placeholder="your phone">
                        </div>
                        
                    </div>
                    <div class="form-single">
                        <div class="form-item">
                            <label class="label" for="subject">Subject</label><br>
                            <input
                                class="input" 
                                v-model="subject" 
                                type="text" 
                                id="subject" 
                                name="subject" 
                                placeholder="Select a subject">
                        </div>
                        <div class="form-item">
                            <label 
                                :class="{ 'accept-error' : v$.name.$error }"
                                class="label" 
                                for="message">Message*</label><br>
                            <textarea 
                                :class="{ 'form-error' : v$.message.$error }"
                                class="input textarea" 
                                v-model="message" 
                                type="textarea" 
                                id="message" 
                                name="message" 
                                placeholder="Type your message...">
                            </textarea>
                        </div>
                        
                    </div>
                    <!-- <div
                        :class="{ 'accept-error' : v$.acceptTerms.$error }" 
                        class="form-agree">
                        <input 
                            v-model="acceptTerms" 
                            type="checkbox" 
                            id="agree">
                        <label for="agree">
                            <span>I have read and agree to the </span>
                            <a href="#">privacy policy</a>    
                        </label>
                    </div> -->
                    <div
                        :class="{ 'accept-error' : v$.acceptTerms.$error }" 
                        class="form-agree">
                        <label 
                            for="agree"
                            class="checkbox-label">
                            <input 
                            v-model="acceptTerms" 
                            type="checkbox" 
                            id="agree">
                            <div class="form-agree-text">
                                <span>I have read and agree to the </span>
                                <a href="pdf/altalanos-adatvedelmi-tajekoztato.pdf" target="_blank">privacy policy</a>
                            </div>
                        </label>
                    </div>
                    <div class="form-submit">
                        <button
                            @click.prevent="submitForm"
                            :disabled="isDisabled" 
                            type="submit"
                            class="button submit__button">
                            <transition name="slide-up" mode="out-in">
                                <div v-if="sendBtn.send" class="button__text submit__button--send">SEND</div>
                            </transition>
                            <transition name="slide-up" mode="out-in">
                                <div v-if="sendBtn.sending" class="">
                                    <div class="button__text submit__button--sending">SENDING
                                        <div class="submit__button--sending--dot"></div>
                                        <div class="submit__button--sending--dot"></div>
                                        <div class="submit__button--sending--dot"></div>
                                    </div>
                                    
                                    
                                </div>
                            </transition>
                            <transition name="slide-up" mode="out-in">
                                <div 
                                    v-if="sendBtn.sent"
                                    class="button__text submit__button--sent">
                                    <p class="button__text">SENT</p>
                                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                                </div>
                            </transition>
                        </button>
                        <span 
                            v-if="isError"
                            class="form-error-message">Failed to send. Please try again!</span>
                    </div>
                </form>
            </div>

            <div class="contact__info">
                <div class="contact__info--title">
                    <h3>What's next?</h3>
                </div>
                <div v-for="txt in text" :key="txt.img">
                    <img class="contact__info--icon" :src="'/img/' + txt.img" alt="txt.img">
                    <h5 class="contact__info--subtitle">{{txt.title}}</h5>
                    <p class="contact__info--text">{{txt.text}}</p>
                </div>
                <div class="contact__info--portfolio">
                    <p class="semi-bold">Want to know more?</p>
                    <div class="contact__info--portfolio--items">
                        <a href="/credentials/en.pdf" target="_blank">Download our portfolio (EN)</a>
                        <p class="contact__info--portfolio--line"> / </p>
                        <a href="/credentials/hu.pdf" target="_blank">Portfolió megtekintése (HU)</a>
                    </div>
                    <div>
                        <a href="/pdf/Modan_VEKOP_kotelezo_nyilvanossagi_dokumentum.pdf" target="_blank">Vekop kötelező nyilvánossági dokumentum</a>
                        <img src="/img/vekop.jpg" alt="Vekop" class="contact__vekop-img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import axios from 'axios'
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    data () {
        return {
            text: {
                1: {
                    img: "talk.svg",
                    title: "TALK THINGS OVER",
                    text: "After reaching out to us, we'll get in touch with you to get more info about your ideas and goals."
                },
                2: {
                    img: "put.svg",
                    title: "WE'LL PUT OUR HEADS TOGETHER",
                    text: "Once your objectives are clear to us, we'll start planning so we can get back to you with our proposal and recommendation, along with a preliminary timeline and budget estimate."
                },
                3: {
                    img: "hands.svg",
                    title: "WE SHAKE HANDS",
                    text: "Once we're on the same page, we can start building your brand's future together."
                }        
            },
            name: "",
            company: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            acceptTerms: "",
            sendBtn: {
                send: true,
                sending: false,
                sent: false
            },
            isError: false,
            isDisabled: false
        }
    },
    validations () {
        return {
            name: {
                required
            },
            email: {
                required,
                email
            },
            message: {
                required
            },
            acceptTerms: {
                required
            }
        }
    },
    methods: {
       submitForm() {
            this.v$.$validate()
            if(!this.v$.$error) {
                this.formSubmit()
            } else {
                console.log(this.v$.$error)
                
            }
        },
        formSubmit() {
            axios
                .post(process.env.VUE_APP_API_URL, {
                    name: this.name,
                    company: this.company,
                    email: this.email,
                    phone: this.phone,
                    subject: this.subject,
                    message: this.message
                })
                .then(() => {
                    this.isError = false
                    this.animateButton()
                })
                .catch((error) => {
                    console.log(error)
                    this.isError = true
                })
        }, 
        animateButton() {
            setTimeout(() => {
                this.isDisabled = true
                this.sendBtn.send = false
                this.sendBtn.sending = true
                setTimeout(() => {
                    this.sendBtn.sending = false
                    this.sendBtn.sent = true
                    setTimeout(() => {
                        this.sendBtn.sent = false
                        this.sendBtn.send = true
                        this.clearForm()
                        this.isDisabled = false
                    }, 5000)
                }, 3000)    
            }, 100)
        },
        clearForm() {
            
                this.name = '',
                this.company = '',
                this.email = '',
                this.phone = '',
                this.subject = '',
                this.message = '',
                this.acceptTerms = false,
                this.v$.$reset()            
        }
    }
}
</script>