import Vue from 'vue'
import App from './App.vue'

import '@/sass/main.sass'

const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease-in",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
