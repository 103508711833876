<template>
    <div :class="[ 'custom-cursor', { 'custom-cursor__hover': hover }, {'custom-cursor_hide': hideCursor} ]">
        <div :style="cursorCircle" class="custom-cursor__circle"></div>
        <div class="custom-cursor__point" ref="point" :style="cursorPoint"></div>
    </div>
</template>

<script>
export default {
  data () {
     return{
        xChild: 0,
        yChild: 0,
        xParent: 0,
        yParent: 0,
        hover: false,
        hideCursor: true
     }
     
  },
  computed: {
    cursorCircle() {
      return `transform: translateX(${this.xParent}px) translateY(${this.yParent}px) translateZ(0) translate3d(0, 0, 0);`
    },
    cursorPoint() {
      return `transform: translateX(${this.xChild - 3}px) translateY(${this.yChild - 3}px) translateZ(0) translate3d(0, 0, 0);`
    }
  },
  methods: {
    moveCursor(e) {
      this.xChild = e.clientX
      this.yChild = e.clientY
      
      setTimeout(() => {
        this.xParent = e.clientX - 65
        this.yParent = e.clientY - 65
      }, 100)
    }
  },
  mounted() {
    this.screenHeight = window.innerHeight
    document.addEventListener("mousemove", this.moveCursor)
    document.addEventListener("mouseleave", () => {
      this.hideCursor = true
    })
    document.addEventListener('mouseenter', () => {
      this.hideCursor = false
    })
  }  
}
</script>