<template>
    
    <div 
        class="hero" 
        v-on:mousemove="eraseCanvas($event)"
        >
        
        <Overlay/>

        <canvas id="canvas"></canvas>

        <div class="hero__bcg">
            <div class="hero__bcg--img"></div>
        </div>

        <div class="hero__title">
            <h1 class="semi-bold">website is</h1>
            <h1 class="semi-bold">
                <span class="hero__title--textbox">
                    coming soon*
                    <span class="hero__title--textbox--body"></span>
                    <span class="hero__title--textbox--left"></span>
                    <span class="hero__title--textbox--right"></span>
                </span>
            </h1>
        </div>

        <div class="hero__bottom">
            
            <div class="hero__bottom--note">
                <h4>{{note}}</h4>
                <a v-scroll-to="'#contact'">
                    <div class="hero__bottom--note--arrow">
                        <img src="/img/arrow-down.svg" alt="arrow-down">
                    </div>
                </a>
            </div>

            <div class="hero__bottom--excerpt">
                <h4 class="semi-bold">{{excerptBold}}</h4>
                <h4>{{excerpt}}</h4>
            </div>

        </div>

        <div class="hero__logo">
            <img src="/img/modan-logo.svg" alt="modan">
        </div>

        <div class="hero__btn">
            <Button :button="button" link="#contact" /> 
        </div>
        
    </div>
    
    
</template>

<script>
import Button from '@/components/decors/Button.vue'
import Overlay from '@/components/decors/Overlay.vue'

export default {
    components: {
        Button,
        Overlay
    },
    data () {
        return {
            old: Object,
            breakPoint: 1024,
            button: "contact us",
            excerptBold: "We’re Modan, a digital product house based in Budapest, Hungary.", 
            excerpt: "We create thoughtful experiences for humans and brands with soul.",
            note: "*We’re too busy working on our current projects"
        }
    },
    methods: {
        eraseCanvas(event){
            if(window.innerWidth > this.breakPoint) {
                setTimeout(() => {
                    this.bounds = this.c.getBoundingClientRect()
                    let x = event.clientX - this.bounds.left
                    let y = event.clientY - this.bounds.top
                    this.ctx.globalCompositeOperation = 'destination-out'
                    this.ctx.beginPath()
                    this.ctx.arc(x, y, 100, 0, 2 * Math.PI)
                    this.ctx.fill()
                    this.ctx.lineWidth = 200
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.old.x, this.old.y)
                    this.ctx.lineTo(x, y)
                    this.ctx.stroke()
                    this.old = {x: x, y: y}    
                }, 400)
            }
        },
        drawCanvas() {
            this.c = document.getElementById('canvas')
            this.c.width = window.innerWidth
            this.c.height = window.innerHeight
            this.ctx = this.c.getContext('2d')
            this.ctx.fillStyle = '#000000'
            this.ctx.fillRect(0, 0,window.innerWidth ,window.innerHeight)
            }       
    },
    mounted() {
        if(window.innerWidth > this.breakPoint) {
            this.drawCanvas()
            window.addEventListener('resize', this.drawCanvas)
            this.drawCanvas()
        }

        
    },
    destroyed() {
        if(window.innerWidth > this.breakPoint) {
            window.removeEventListener('resize', this.drawCanvas)
        }
    }
}
</script>